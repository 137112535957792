.hero {
  &-products {
    height: 642px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left top;
    &-packaging {
      background-image: url("../images/packaging/tray.png");
      h1 {
        color: $darkBlue;
      }
    }
    &-compost {
      background-image: url("../images/compost.jpg");
    }
    &-other {
      background-image: url("../images/compost.jpg");
    }
  }
}

.product {
  &-overview {
    margin: 30px 0;
    padding: 50px 0;
    text-align: center;
    h1 {
      color: $biabBlue;
      margin-bottom: 40px;
    }
    h2 {
      font-size: 1.125rem;
      font-weight: 400;
    }
    p {
      margin: 20px auto;
      max-width: 84ch;
    }
    img {
      height: 500px;
    }
  }

  &-designs {
    background-color: $biabBlue;
    padding: 40px 0;
    min-height: 640px;
    h1,
    p {
      color: white;
    }
    img {
      height: 500px;
    }
  }

  &-samples {
    padding: 40px 0;

    img {
      max-width: 90%;
    }
  }

  &-contact {
    background-color: $paleBlue;
    padding: 40px 0;
    h1 {
      color: $blue;
    }
    p {
      margin: 20px 0 40px;
    }
  }
}
.compost {
  &-overview {
    margin: 0px 0;
    padding: 80px 0;
    text-align: center;
    background-color: #efefef;
    h1 {
      color: $biabBlue;
      margin-bottom: 40px;
    }
    h2 {
      font-size: 1.125rem;
      font-weight: 400;
    }
    p {
      margin: 20px auto;
      max-width: 84ch;
    }
    img {
      height: 500px;
    }
  }

  &-designs {
    padding: 40px 0;
    h1,
    p {
      color: white;
    }
    img {
      height: 500px;
    }
  }

  &-list {
    padding: 40px 0;
    background-color: $white;
    h1 {
      margin-top: 40px;
      text-align: center;
    }
    h4 {
      color: $darkBlue;
    }
    img {
      max-width: 78%;
    }

    .reduced-height {
      max-width: 49%;
    }
    .flex-column-item {
      &:hover {
        border: 1px solid #c9c9c9;
        border-radius: 5px;
      }
    }
  }

  &-contact {
    background-color: $paleBlue;
    padding: 40px 0;
    h1 {
      color: $blue;
    }
    p {
      margin: 20px 0 40px;
    }
  }
}
