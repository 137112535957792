.has-fade {
  visibility: hidden;
}

@keyframes fade-in {
  from {
    visibility: hidden;
    opacity: 0;
  }
  1% {
    visibility: visible;
    opacity: 0;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
}
.fade-in {
  animation: fade-in 200ms ease-in-out forwards;
}

@keyframes fade-out {
  from {
    visibility: visible;
    opacity: 1;
  }
  99% {
    visibility: visible;
    opacity: 0;
  }
  to {
    visibility: hidden;
    opacity: 0;
  }
}
.fade-out {
  animation: fade-out 200ms ease-in-out forwards;
}

.inline-photo {
  border: 1em solid #fff;
  border-bottom: 4em solid #fff;
  border-radius: 0.25em;
  box-shadow: 1em 1em 2em 0.25em rgba(0, 0, 0, 0.2);
  margin: 2em auto;
  opacity: 0;
  transform: translateY(4em) rotateZ(-5deg);
  transition: transform 4s 0.25s cubic-bezier(0, 1, 0.3, 1),
    opacity 0.3s 0.25s ease-out;
  max-width: 600px;
  width: 90%;
  //   will-change: transform, opacity;
}

.inline-section {
  opacity: 0;
  transform: translateY(1em);
  transition: transform 4s 0.25s cubic-bezier(0, 1, 0.3, 1),
    opacity 0.3s 0.25s ease-out;
  will-change: transform, opacity;
}

.inline-section-vert {
  opacity: 0;
  transform: translate(0, 50px);
  transition: transform 4s 0.25s cubic-bezier(0, 1, 0.3, 1),
    opacity 0.3s 0.25s ease-out;
  will-change: transform, opacity;
}

.inline-section-horiz {
  opacity: 0;
  transform: translateX(4em);
  transition: transform 4s 0.25s cubic-bezier(0, 1, 0.3, 1),
    opacity 0.3s 0.25s ease-out;
  will-change: transform, opacity;
}

.inline-photo.is-visible {
  opacity: 1;
  transform: rotateZ(-2deg);
}

.inline-section.is-visible {
  opacity: 1;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}
.inline-section-horiz.is-visible {
  opacity: 1;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}
.inline-section-vert.is-visible {
  opacity: 1;
  transform: translate(0px, 0px);
  // transform-style: preserve-3d;
}
