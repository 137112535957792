.hero {
  &-services {
    height: 642px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left top;
    background-image: url("../images/handsqueeze.png");
  }
}

.services-overview {
  .container {
    text-align: left;
    margin: 30px auto;
    padding: 40px 0 20px;
    h1 {
      margin-bottom: 40px;
      color: $biabBlue;
    }
    p {
      margin: 20px auto;
      max-width: 84ch;
      text-align: left;
    }
    img {
      width: 400px;
    }
  }
}

.our-services {
  background-color: $biabBlue;
  padding: 80px 0;
  .container {
    margin: 0 auto;
    text-align: center;
  }
  .services-sub-header {
  }
  h1 {
    color: white;
  }
  h2 {
    font-size: 0.75rem;
    font-weight: 300;
    margin: 0.7rem 0 0 0;
    color: #e7e288;
    padding-bottom: 5px;
  }
  h3 {
    margin: 0.2rem 0 0.5rem 0;
    font-size: 1.125rem;
    font-weight: 500;
    color: white;
  }
  p {
    margin: 0;
    text-align: center;
    font-size: 1rem;
    color: white;
  }

  img {
    height: 40px;
    width: 40px;
  }
  .services-list {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 900px) {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  .flex-column-item {
    padding: 2rem 0.5rem;
    @media (min-width: 900px) {
      width: 100%;
    }
    &-third {
      @media (min-width: 1000px) {
        width: 33%;
      }
      p {
        max-width: min(80%, 30ch);
        text-align: center;
        margin: 0 auto;
      }
      .fas {
        font-size: 3rem !important;
        padding-bottom: 10px;
        color: #e7e288;
      }
    }
  }
}
