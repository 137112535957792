@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

html {
  font-size: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: $ffPrimary;
  line-height: 1.3;
  color: $grayishBlue;
  font-size: $font-sm;

  @include breakpoint-up(large) {
    font-size: $font-med;
  }
}

a,
a:visited,
a:hover {
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
}

.section {
  margin: 0 auto;
}

.container {
  width: 90%;
  margin: 0 auto;
  @media (min-width: $maxWidth) {
    max-width: $maxWidth;
  }
}

h1,
h2,
h3,
h4 {
  font-family: $ffAccent;
}

h1 {
  color: $biabBlue;
  margin: 0.5rem 0;
  font-weight: 400;
  font-size: 34px;
  @media (min-width: 1000px) {
    font-size: 44px;
  }
}

p {
  line-height: 170%;
  color: $textGrey;
  font-size: $font-med;
  font-weight: 400;
}

// Spacers

.spacer__horizontal {
  content: "";
  margin: 0 1rem;
}

// Visibility
.hide-for-mobile {
  @include breakpoint-down(medium) {
    display: none;
  }
}

.hide-for-desktop {
  @include breakpoint-up(large) {
    display: none;
  }
}

// FLEX

.flex {
  display: flex;
  flex-direction: column;
  @media (min-width: 1000px) {
    flex-direction: row;
  }
  &-jc-sb {
    justify-content: center;
    @media (min-width: 1000px) {
      justify-content: space-between;
    }
  }

  &-jc-c {
    justify-content: center;
  }

  &-ai-c {
    align-items: center;
  }
  &-ai-fs {
    align-items: center;
    @media (min-width: 1000px) {
      align-items: flex-start;
    }
  }
  &-ai-fe {
    align-items: center;
    @media (min-width: 1000px) {
      align-items: flex-end;
    }
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
  &-jc-sb {
    justify-content: space-between;
  }
  &-jc-fs {
    justify-content: center;
    @media (min-width: 1000px) {
      justify-content: flex-start;
    }
  }
  &-jc-fe {
    justify-content: center;
    @media (min-width: 1000px) {
      justify-content: flex-end;
    }
  }
  &-jc-c {
    justify-content: center;
  }

  &-ai-c {
    align-items: center;
  }
  &-ai-fs {
    align-items: center;
    @media (min-width: 1000px) {
      align-items: flex-start;
    }
  }
  &-ai-fe {
    align-items: center;
    @media (min-width: 1000px) {
      align-items: flex-end;
    }
  }
}

.flex-column-item {
  padding: 20px 0;
  width: 100%;
  margin: 10px 0;
}

.flex-column-item__ra {
  text-align: right;
}

// Buttons
.button {
  border-radius: 10px;
  font-weight: 400;
  padding: 0.4em 1.95em;
  line-height: 2em;
  letter-spacing: 1px;
  min-width: fit-content;
  max-width: fit-content;
  position: relative;
  display: inline-block;
  // padding: 0.875rem 2.1875rem;
  // background-image: linear-gradient(to right, $blue, $blue);
  border: 0;
  cursor: pointer;
  font-size: $font-sm;
  transition: opacity 300ms ease-in-out;
  overflow: hidden;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   background-color: rgba(255, 255, 255, 0.25);
  //   opacity: 0;
  //   transition: opacity 150ms ease-in-out;
  // }

  &:hover::before {
    opacity: 1;
  }
}

.button--primary--blue {
  background-color: $biabBlue;
  color: white;
  border: 1px solid $biabBlue;
}
.button--primary--white {
  background-color: $white;
  color: $biabBlue;
  border: 1px solid $white;
  font-weight: bold;
}

.button--secondary {
  background: transparent;
  color: $blue;
  border: 1px solid $blue;
}

.button--outlined {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}

// Logos

.fas,
.far {
  color: $blue;
}
