.hero {
  &-contact {
    padding: 0;

    height: 580px;

    h1 {
      color: $biabBlue;
      font-size: 18px;
      font-weight: 600;
    }
    p {
      color: inherit;
      width: inherit;
      font-size: $font-sm;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 1.5;
    }
  }
  #content {
    width: max-content;
    padding: 10px 15px 0;
  }
}

#maps * {
  box-sizing: content-box;
}

#map {
  height: 100%;
  width: 100%;
}

.contact {
  width: min(100%, 790px);
  padding: 40px 0 100px;
  .contact__logo {
    margin: 1rem 0;
  }

  h1 {
    margin: 2rem 0;
  }

  form {
    width: 100%;
  }

  .name {
    grid-area: name;
  }
  .email {
    grid-area: email;
  }
  .subject {
    grid-area: subject;
  }
  .message {
    grid-area: message;
  }
  .submit {
    grid-area: submit;
    justify-self: center;
  }

  .form__layout {
    display: grid;
    // justify-content: stretch;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "name email"
      "subject subject"
      "message message"
      "submit submit";
    column-gap: 10px;
    row-gap: 15px;
  }

  input[type="email"],
  input[type="password"],
  input[type="text"],
  textarea {
    -moz-transition: background-color 0.2s ease-in-out,
      border-color 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s ease-in-out,
      border-color 0.2s ease-in-out;
    -ms-transition: background-color 0.2s ease-in-out,
      border-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    background: none;
    border: 1px solid rgba(124, 128, 129, 0.2);
    border-radius: 0;
    color: inherit;
    display: block;
    padding: 0.75em;
    text-decoration: none;
    width: 100%;
    outline: 0;
  }

  input,
  select,
  textarea {
    color: $darkBlue;
    font-family: $ffPrimary;
    background-color: white;
    font-weight: 400;
  }

  .button {
    max-width: max-content;
  }
}
