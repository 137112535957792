.header {
  .container {
    padding: 0.813rem 0rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  // Hamburger Open Styles
  &.open {
    .overlay {
      animation: fade-in 300ms ease-in-out forwards;
    }
    .header__toggle {
      > span:first-child {
        transform: rotate(45deg);
      }

      > span:nth-child(2) {
        opacity: 0;
      }

      > span:last-child {
        transform: rotate(-45deg);
      }
    }
  }

  &__logo {
    img {
      height: 62px;
    }
  }

  &__toggle {
    // Mobile Menu Toggle

    > span {
      display: block;
      width: 26px;
      height: 2px;
      background-color: $darkBlue;
      transition: all 300ms ease-in-out;
      transform-origin: 3px 1px;

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
  .overlay {
    opacity: 0;
    position: fixed;
    top: 91px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-image: linear-gradient($darkBlue, transparent);
  }

  // &__toggle {
  //   display: block;
  //   width: 26px;
  //   transform: translateX(-50%);
  //   background: $white;
  //   border-radius: 5px;

  //   > span {
  //     display: block;
  //     width: 26px;
  //     height: 2px;
  //     background-color: $darkBlue;
  //     transition: all 300ms ease-in-out;
  //     transform-origin: 3px 1px;

  //     &:not(:last-child) {
  //       margin-bottom: 5px;
  //     }
  //   }

  // a {
  //   display: block;
  //   padding: 0.625rem;
  //   color: $darkBlue;
  //   text-align: center;
  // }
  // }

  &__menu {
    position: absolute;
    width: calc(100% - 3rem);
    left: 50%;
    transform: translateX(-50%);
    background: $white;
    margin-top: 1.5rem;
    padding: 1.625rem;
    border-radius: 5px;
    z-index: 100;

    a {
      display: block;
      padding: 0.625rem;
      color: $darkBlue;
      // text-align: center;
      font-size: 1.125rem;
      &:not(:first-child) {
        border-top: 1px solid #e4e4e4;
      }
    }

    &__sub-category {
      font-size: 1rem !important;
      border-top: none !important;
      padding-left: 20px !important;
    }
  }

  &__links {
    a {
      font-family: $ffPrimary;
      text-transform: uppercase;
      color: $biabBlue;
      font-weight: 400;
      &:not(:last-child) {
        margin-right: 1.875rem;
      }
    }

    .active {
      font-weight: 600;
    }
  }

  h1 {
    color: red;
  }

  @media (min-width: $maxWidth) {
  }
}

/* The dropdown container */
.dropdown {
  display: inline-block;
  // float: left;
  // overflow: hidden;
}

/* Dropdown button */
.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
}

/* Add a red background color to navbar links on hover */
.navbar a:hover,
.dropdown:hover .dropbtn {
  background-color: red;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  padding: 20px 0;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  float: none;
  color: $biabBlue;
  padding: 20px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}
