.footer {
  padding: 3em 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: $veryDarkBlue;

  &__social-media {
    padding-bottom: 20px;
  }

  &__links {
    text-align: center;
  }

  .copyright {
    padding: 0;
    margin: 0;

    li {
      margin-left: 0.5em;
      padding-left: 0.6em;
      border-left: none;
      line-height: 1.8;
      font-weight: 500;
      font-size: 14px;
      display: inline;
      color: white;
      font-weight: 500;
    }

    a {
      color: inherit;
    }

    &--links {
      margin-bottom: 10px;
    }

    &--light {
      color: $paleGrey;
      margin: 0.5rem 0;

      li {
        padding: 0;
        font-size: 13px;
        font-weight: 400;
        color: $paleGrey;
      }

      a {
        color: inherit;
      }
    }

    &--terms {
      color: $paleGrey;
      font-weight: 400;
      font-size: 12px;
      li:not(:first-child) {
        margin: 0;
        padding: 0;
        font-size: 13px;
        font-weight: 400;
        margin-left: 5px;
        padding-left: 7px;
        border-left: 1px solid grey;
      }
    }

    .icon.circle {
      width: fit-content;
    }
  }
  a {
    border-bottom: none;
  }

  @media (min-width: 900px) {
    padding: 3em 5em;
  }
}
