// Font family
$ffAccent: "Roboto", sans-serif;
$ffPrimary: "Open Sans", sans-serif;

// Colors
$paleBlue: hsl(210, 100%, 91%);
$darkBlue: hsl(233, 26%, 24%);
// $biabBlue: hsl(219, 46%, 38%);
$biabBlue: hsl(205, 84%, 25%);
$veryDarkBlue: hsl(201, 100%, 4%);
$limeGreen: hsl(136, 65%, 51%);
$brightCyan: hsl(192, 70%, 51%);
$grayishBlue: hsl(233, 8%, 62%);
$lightGrayishBlue: hsl(220, 16%, 96%);
$veryLightGray: hsl(0, 0%, 98%);
$white: hsl(0, 0%, 100%);
$yellow: hsl(58, 67%, 55%);
$blue: hsl(205, 72%, 43%);
$textGrey: hsl(0, 0%, 20%);
$backgroundGrey: hsl(0, 0%, 95%);
$paleGrey: hsl(0, 0%, 60%);
$green: hsl(93, 31%, 66%);
// Font Sizes
$font-sm: 0.875rem; // 14px
$font-med: 1.125rem; // 18px
$font-lg: 1.25rem;
$font-xlg: 3.25rem;

// Sizes
$maxWidth: 1200px;
