.hero {
  &-about {
    height: 642px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left bottom;
    background-image: url("../images/biab_office.jpg");
  }
}

.about-us-overview {
  .container {
    text-align: center;
    margin: 30px auto;
    padding: 40px 0 20px;
    h1 {
      margin-bottom: 40px;
    }
    p {
      margin: 20px auto;
      max-width: 84ch;
    }
  }
}
.about-us-focus {
  margin: 60px auto;
  padding: 40px 0 20px;

  background-color: $biabBlue;
  h1 {
    color: white;
  }
  p {
    color: white;
    margin-left: 0;
  }
  ul {
    list-style: none;
    margin: 0 0 0 20px;
    padding-left: 0;
  }

  li {
    padding-left: 2em;
    text-indent: -1em;
    color: white;
    padding: 20px 0;
    font-size: $font-med;
  }

  li:before {
    content: "*";
    padding-right: 10px;
  }

  img {
    width: 350px;
  }
}

.about-us-sustainable {
  .container {
    text-align: center;
    margin: 30px auto 50px;
    .flex-column-item:first-child {
      padding-right: 0;
    }
    .flex-column-item:last-child {
      padding-left: 0;
    }
    @media (min-width: 1000px) {
      .flex-column-item:first-child {
        padding-right: 40px;
      }
      .flex-column-item:last-child {
        padding-left: 40px;
      }
    }
    padding: 40px 0;
    h1 {
      margin-bottom: 0px;
    }
    h2 {
      color: $blue;
      font-weight: 400;
      margin-bottom: 0;
    }
    p {
      margin: 20px auto 40px;
      max-width: 84ch;
    }
    img {
      width: 600px;
    }
  }
}
.about-contact {
  background-color: $paleBlue;
  padding: 40px 0 60px;
  h1 {
    color: $blue;
    text-align: center;
    @media (min-width: 1000px) {
      text-align: start;
    }
  }
  p {
    text-align: center;
    margin: 20px 0 0;
    max-width: 84ch;
    &:last-of-type {
      margin-bottom: 40px;
    }
  }
}
