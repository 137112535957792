.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 0;

  &-home {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left bottom;
    background-color: $biabBlue;
    background: linear-gradient(
      90deg,
      $biabBlue 7%,
      rgba(98, 139, 179, 1) 45%,
      rgba(228, 235, 242, 1) 100%
    );
    &__logo {
      width: 600px;
    }
    &-content {
      display: flex;
      max-width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      &-image {
        text-align: center;
        img {
          width: 80%;
        }
      }
      @media (min-width: 1000px) {
        flex-direction: row;
      }
    }
  }

  .background-overlay {
    height: 710px;
    width: 100%;
  }

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  h1 {
    font-size: 3rem;
    font-weight: 300;
    color: white;
    margin: 15px 0;
  }
  p {
    color: white;
    font-weight: 300;
    font-size: 1.4rem;
    margin: 1rem 0 1.9rem 0;
    padding: 0;
    letter-spacing: 1.8px;
    line-height: 1.3;
    width: 44ch;
  }
  &__links {
    width: 100%;
  }
}

.what-we-do {
  margin: 1rem 0;
  padding: 3rem 0;
  min-height: 400px;
  text-align: center;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: $maxWidth) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  h1 {
    color: $biabBlue;
    margin-bottom: 1rem;
  }

  p {
    font-size: 22px;
    line-height: 1.3;
    width: min(90%, 45ch);
    text-align: left;
  }

  .button {
    margin-top: 20px;
  }

  img {
    width: 70%;
    margin: 1rem 0;
  }
}

.why-choose-us {
  padding: 40px 0;
  min-height: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: $biabBlue;

  .container {
    margin: 2rem auto;
    height: 100%;
    max-width: $maxWidth;
  }

  .flex.items {
    flex-direction: column;

    @media (min-width: $maxWidth) {
      flex-direction: row;
    }
  }

  .flex-column-item {
    margin: 10px;
    img {
      width: 50px;
    }
  }

  .items {
    margin: 2rem 0;
  }

  h1 {
    font-weight: 400;
    color: white;
  }

  h2 {
    font-family: $ffPrimary;
    font-size: 1.125rem;
    font-weight: 600;
    text-transform: uppercase;
    color: $darkBlue;
    letter-spacing: 0.45px;
    color: white;
  }

  p {
    width: 83%;
    color: white;
  }

  .far,
  .fas {
    font-size: 3rem;
    color: white;
  }
}

.why-work-with-us {
  background-color: $paleBlue;
  padding: 80px 0;
  text-align: center;
  .title {
    width: 60%;
  }

  img {
    width: 70%;
  }
  p {
    text-align: left;
  }
}
.our-customers {
  background-color: $paleBlue;
  text-align: center;
  padding: 40px 0;

  &-categories {
    background-color: white;
  }

  h1 {
    font-weight: 400;
    color: $biabBlue;
  }
  h2 {
    font-size: 22px;
    font-weight: 400;
    color: $darkBlue;
    margin: 0 0 20px 0;
  }
  .flex-column-item {
    margin: 0;
    @media (min-width: 1000px) {
      margin: 30px 0;
    }
  }
  .container {
    max-width: $maxWidth;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  img {
    max-width: 170px;
    height: auto;
    max-height: 70px;
  }
  .ramada {
    margin-top: 26px;
    @media (min-width: 1000px) {
      margin-top: 58px;
    }
  }
  .armed-forces {
    img {
      max-width: 250px;
    }
  }

  &__public-sector {
    display: flex;
    flex-direction: column;
    align-items: center;
    .flex-column-item {
      max-width: 66%;
      margin: 0;
      height: 100%;
    }
    .customers-box {
      background-color: #f5fafe;

      margin: 0 0.75rem;
      padding: 1.5rem;
      border: 1px solid $paleBlue;
      border-radius: 4px;
      color: #333333;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media (min-width: 1000px) {
      flex-direction: row;
      .flex-column-item {
        max-width: 100%;
        height: 100%;
      }
      .customers-box {
        min-height: 144px;
      }
    }
  }
}

.testing-and-quality {
  margin: 0 auto;
  padding: 40px 0;
  min-height: 400px;
  max-width: $maxWidth;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  .flex-column-item {
    padding: 0rem;
  }
  h1 {
    font-weight: 400;
    color: $biabBlue;
  }
  .container {
    height: 100%;
  }
  @media (min-width: 1000px) {
    .flex-column-item {
      padding: 2rem;
    }
    .flex-column-item:first-child {
    }
  }
  img {
    height: 100px;
  }
}
